/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from 'react';
import { AnimatePresence } from "framer-motion";

export const wrapPageElement = ({element}) => (
  <AnimatePresence initial={false} mode="wait" >{element}</AnimatePresence>
);